<template>
  <v-container fluid fill-height class="page404">
    <v-row align="center" justify="center">
      <v-col cols="6">
        <h1 class="float-left display-3 mr-4">404</h1>
        <h4 class="pt-3">Oops! You're lost.</h4>
        <p class="text-muted">The page you are looking for was not found.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Page404'
}
</script>
<style lang="scss">
.page404 {
  height: calc(100vh - 64px);
}
</style>
